import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

export default function ListePages({ author }) {
    var data = useStaticQuery(graphql`
        query {
            allMdx(filter: {frontmatter: {reference: {in: ["4-23-7-44"]}}}) {
                edges {
                node {
                    frontmatter {
                    reference
                    slug
                    embeddedImagesLocal {
                        childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                    thumbnailText
                    excerpt
                    title
                    }
                }
                }
            }
        }
    `)

// author
// author_spe
// author_slug

    return (
        <div className="mt-4 px-4 relative bg-white pt-12 sm:px-6 lg:py-12 rounded-xl">
            <div className="mx-auto max-w-7xl">
            <div className="mx-auto max-w-2xl lg:max-w-4xl">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Articles de {author} </h2>
                {/* <p className="mt-2 text-lg leading-8 text-gray-600">
                Learn how to grow your business with our expert advice.
                </p> */}
                <div className="mt-8 space-y-8 lg:mt-10 lg:space-y-10">
                    {data.allMdx.edges.map((page) => (
                        <article className="relative isolate flex flex-col gap-8 lg:flex-row">
                            <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                                <a href={page.node.frontmatter.slug}> 
                                    <GatsbyImage 
                                        className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                                        alt={page.node.frontmatter.thumnailText} 
                                        image={getImage(page.node.frontmatter.embeddedImagesLocal[0])} 
                                    />
                                </a>
                            </div>

                            <div>
                                <div className="group relative max-w-xl">
                                    <h3 className="text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                        <a href={page.node.frontmatter.slug}>
                                        <span className="absolute inset-0" />
                                        {page.node.frontmatter.title}
                                        </a>
                                    </h3>
                                    <p className="mt-5 text-sm leading-6 text-gray-600">
                                        {page.node.frontmatter.excerpt}
                                    </p>
                                </div>
                                {/* <div className="relative mt-8 flex items-center gap-x-4">
                                <a href="/maison/fissures-maison/expertise/edieux-expertise/"> 
                                    <StaticImage
                                        className="h-10 w-10 rounded-full bg-gray-100"
                                        src="../../markdown-pages/experts/edieux-expertise/Photo EDIEUX 600x600.jpg"
                                        alt=""
                                    />
                                </a>
                                <div className="text-sm leading-6">
                                    <p className="font-semibold text-gray-900">
                                    <a href="/maison/fissures-maison/expertise/edieux-expertise/">
                                        <span className="absolute inset-0" />
                                        Edieux Expertise
                                    </a>
                                    </p>
                                    <p className="text-gray-600">Expert bâtiment</p>
                                </div>
                                </div> */}
                            </div>


                        </article>
                    ))}
                </div>
            </div>
            </div>
        </div>
    )
}