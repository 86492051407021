// import React, {useState, useRef} from 'react'
import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
// import { StaticImage } from "gatsby-plugin-image"
// import {MailIcon, AtSymbolIcon,PhoneIcon, GlobeIcon, CalendarIcon} from '@heroicons/react/outline'
import { PhoneIcon, GlobeIcon, CalendarIcon } from "@heroicons/react/outline";
// import {LinkedinSquare, FacebookSquare} from "@styled-icons/boxicons-logos"

import { encode } from "js-base64";

import Header from "../components/headers/headerFissuration-generale";
import Ariane from "../components/ariane";
import Footer from "../components/footers/footerFissuration-generale";
import FormExpert from "../components/formulaires/formExpertFiche";

import RelatedPagesExpert from "../components/shortcodes/experts/RelatedPagesExpert";
import RelatedEdieuxExpertise from "../components/shortcodes/experts/ListePagesEdieux-Expertise";
import RelatedDuboisExpertise from "../components/shortcodes/experts/ListePagesDubois-Expertise";
import RelatedJeromeGallaud from "../components/shortcodes/experts/ListePagesJerome-Gallaud";
import RelatedEmilieRoux from "../components/shortcodes/experts/ListePagesEmilie-Roux";
import RelatedVincentMoreau from "../components/shortcodes/experts/ListePagesVincent-Moreau";
// import RelatedMathieuMaillard from "../components/shortcodes/experts/ListePagesMathieu-Maillard";
import RelatedOpenGroupe from "../components/shortcodes/experts/ListePagesOpen-Groupe";
// import RelatedPascalPloix from "../components/shortcodes/experts/ListePagesPascal-Ploix";

// var image_profil = "image_temp.png"
////Intégration Shortcodes
import Europe1Juin2023Fissures from "src/components/shortcodes/Europe1Juin2023Fissures";
import Europe1Juin2023FissuresIMG from "src/components/shortcodes/Europe1Juin2023FissuresIMG";
import CTAExpertPresChezVous from "src/components/shortcodes/CTAExpertPresChezVous";
import CTAKitFissuration from "src/components/shortcodes/CTAKitFissuration";

// CTA Expert
import CTAOpengroupe from "src/components/shortcodes/cta/CTAOpengroupe";
import CTAEdieuxExpertise from "src/components/shortcodes/cta/CTAEdieuxExpertise";
import CTADuboisExpertise from "src/components/shortcodes/cta/CTADuboisExpertise";
import CTACabinetE2PPloixExpertise from "src/components/shortcodes/cta/CTACabinetE2PPloixExpertise";
import CTALunoaExpertConseilBenamar from "src/components/shortcodes/cta/CTALunoaExpertConseilBenamar";
import CTAAvayahJeromeGallaud from "src/components/shortcodes/cta/CTAAvayahJeromeGallaud";
import CTACabinetLespagnol from "src/components/shortcodes/cta/CTACabinetLespagnol";
import CTAEGExpabatRoux from "src/components/shortcodes/cta/CTAEGExpabatRoux";
import CTAVincentMoreau from "src/components/shortcodes/cta/CTAVincentMoreau";
import CTABatExpert34 from "src/components/shortcodes/cta/CTABatExpert34";
import CTABenoitToussaint from "src/components/shortcodes/cta/CTABenoitToussaint";
import CTAMathieuMaillardGers from "src/components/shortcodes/cta/CTAMathieuMaillardGers";
import CTANouryExpertises from "src/components/shortcodes/cta/CTANouryExpertises";

// CTA Batiment
import CTARenfortSolutions from "src/components/shortcodes/cta/CTARenfortSolutions";
import CTAGeoStudy from "src/components/shortcodes/cta/CTAGeoStudy";
import CTA1GSolutions from "src/components/shortcodes/cta/CTA1GSolutions";
import CTA2RExpertise from "src/components/shortcodes/cta/CTA2RExpertise";
import { defaults } from "autoprefixer";

const shortcodes = {
  CTAExpertPresChezVous,
  Europe1Juin2023Fissures,
  Europe1Juin2023FissuresIMG,
  CTAOpengroupe,
  CTAEdieuxExpertise,
  CTADuboisExpertise,
  CTACabinetE2PPloixExpertise,
  CTALunoaExpertConseilBenamar,
  CTAAvayahJeromeGallaud,
  CTACabinetLespagnol,
  CTAEGExpabatRoux,
  CTAVincentMoreau,
  CTABatExpert34,
  CTABenoitToussaint,
  CTAMathieuMaillardGers,
  CTANouryExpertises,
  CTARenfortSolutions,
  CTAKitFissuration,
  CTAGeoStudy,
  CTA1GSolutions,
  CTA2RExpertise,
};
////

const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: {
        date,
        reference,
        slug,
        title,
        template,
        modele,
        title_meta,
        description_meta,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane,
        expert_nom,
        expert_prenom,
        expert_cabinet,
        expert_adresse,
        expert_cp,
        expert_ville,
        expert_email,
        expert_telephone,
        expert_site,
        expert_linkedin,
      },
      body,
    },
  },
}) => {
  const encryptedMail = encode(expert_email);
  // const encryptedMail= encode("vintux@gmail.com");
  const moyen = "Formulaire Koudepouce Fiche " + modele;

  var url_image_profil = "";
  if (embeddedImagesLocal === null || embeddedImagesLocal === undefined) {
    url_image_profil = "";
  } else {
    url_image_profil = embeddedImagesLocal[0];
  }

  // // Extraction de la liste des relatred pages par expert
  var ListePages = RelatedPagesExpert;

  // console.log(slug)
  switch (slug) {
    case "/maison/fissures-maison/expert-fissures/edieux-expertise/":
      ListePages = RelatedEdieuxExpertise;
      break;
    case "/maison/fissures-maison/expert-fissures/dubois-expertise/":
      ListePages = RelatedDuboisExpertise;
      break;
    case "/maison/fissures-maison/expert-fissures/avayah-jerome-gallaud/":
      ListePages = RelatedJeromeGallaud;
      break;
    case "/maison/fissures-maison/expert-fissures/eg-expabat-emilie-roux/":
      ListePages = RelatedEmilieRoux;
      break;
    case "/maison/fissures-maison/expert-fissures/vincent-moreau/":
      ListePages = RelatedVincentMoreau;
      break;
    // case "/maison/fissures-maison/expert-fissures/mathieu-maillard-eam2/":
    //   ListePages = RelatedMathieuMaillard
    //   break
    case "/maison/fissures-maison/expert-fissures/opengroupe/":
      ListePages = RelatedOpenGroupe;
      break;
    // case "/maison/fissures-maison/expert-fissures/pascal-ploix-cab-e2p/":
    //   ListePages = RelatedPascalPloix
    //   break
  }

  return (
    <div className="bg-white">
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title_meta} />
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>
      <Ariane fil_ariane={fil_ariane} />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg bg-white shadow w-7xl">
          <div className="bg-white p-6">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                <div className="flex-shrink-0 mx-auto h-40 w-40 rounded-full">
                  {/* <GatsbyImage className="mx-auto h-40 w-40 rounded-full" alt={thumbnailText} image={getImage(embeddedImagesLocal[0])} /> */}
                  {/* <GatsbyImage className="mx-auto h-40 w-40 rounded-full" alt={thumbnailText} image={getImage(embeddedImagesLocal[0])} /> */}

                  {url_image_profil ? (
                    <GatsbyImage
                      className="mx-auto h-40 w-40 rounded-full"
                      alt={thumbnailText}
                      image={getImage(url_image_profil)}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <h1 className="text-xl font-bold text-gray-900 sm:text-2xl">
                    {expert_cabinet} - {expert_prenom} {expert_nom}
                  </h1>
                  <p className="text-sm font-medium text-gray-600">
                    {expert_adresse}
                    <br />
                    {expert_cp} {expert_ville}
                    <br />
                  </p>

                  {/* {praticien_email ? 
                      <p className="text-sm font-medium text-gray-600"><MailIcon className="inline-flex h-5 w-5 mr-2" />{praticien_email.substring(0,praticien_email.indexOf("@"))}<AtSymbolIcon className="inline-flex h-5 w-5" />{praticien_email.substring(praticien_email.indexOf("@")+1)}</p> 
                    : 
                      ''
                    } */}

                  {expert_telephone ? (
                    <p className="text-sm font-medium text-gray-600">
                      <PhoneIcon className="inline-flex h-5 w-5 mr-2" />
                      {expert_telephone}
                    </p>
                  ) : (
                    ""
                  )}

                  {expert_site ? (
                    <p className="text-sm font-medium text-gray-600">
                      <GlobeIcon className="inline-flex h-5 w-5 mr-2" />
                      <a
                        href={expert_site}
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-600 hover:text-gray-400"
                      >
                        {expert_site}
                      </a>
                    </p>
                  ) : (
                    ""
                  )}
                  {/* {expert_linkedin ? 
                      <p className="inline-flex text-sm font-medium text-gray-600 mt-2">
                        <a href={expert_linkedin} target="_blank" rel="noreferrer" className="text-gray-600 hover:text-gray-400">
                          <LinkedinSquare className="h-6 w-6" />
                          LinkedIn
                        </a>
                      </p>
                    : 
                      ''
                    } */}
                  {/* {praticien_facebook ? 
                      <p className="inline-flex text-sm font-medium text-gray-600 mt-2">
                        <a href={praticien_facebook} target="_blank" rel="noreferrer" className="text-gray-600 hover:text-gray-400">
                          <FacebookSquare className="h-6 w-6" />
                        </a>
                      </p>
                    : 
                      ''
                    } */}
                </div>
                <div className="pt-4 block w-full border-transparent py-2 pl-3 pr-4 text-base center font-medium text-gray-600 hover:text-gray-800 lg:hidden">
                  <a
                    href="#rendez-vous"
                    className="inline-flex w-full items-center mr-6 rounded-md ring-1 ring-spring-bleu bg-spring-bleu px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100"
                  >
                    <CalendarIcon className="w-5 h-5 mr-2" /> Contactez-moi
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="hidden lg:ml-6 lg:flex lg:space-x-8 h-16">
              <a
                href="#presentation"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-900"
              >
                Présentation
              </a>
              <a
                href="#discipline"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Discipline
              </a>
              <a
                href="#seance"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Séances
              </a>
              <a
                href="#formation"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Formation
              </a>
              <a
                href="#specialite"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Spécialité
              </a>
              <a
                href="#autres"
                className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Autres informations
              </a>
            </div> */}
        </div>
      </div>

      <div className="relative max-w-7xl mx-auto h-full lg:flex">
        <div className="flex flex-1 overflow-hidden pb-4">
          <div className="flex flex-1 flex-col">
            <div className="mt-8 max-w-4xl px-4 sm:px-6 lg:px-8 prose">
              <MDXProvider components={shortcodes}>
                <MDXRenderer
                  remoteImages={embeddedImagesRemote}
                  localImages={embeddedImagesLocal}
                >
                  {body}
                </MDXRenderer>
              </MDXProvider>
            </div>

            <ListePages author={expert_cabinet} />
          </div>
        </div>

        <aside
          id="rendez-vous"
          className="self-start top-32 col-span-1 px-8 lg:py-16 lg:w-1/3"
        >
          <div className="flex flex-col flex-grow rounded-lg text-center">
            <FormExpert encryptedMail={encryptedMail} moyen={moyen} />
          </div>

          {/* <div className="flex flex-col flex-grow rounded-lg text-center">
                <div className="flex flex-col flex-grow bg-slate-400 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                  <h3 className="mt-2 text-3xl font-bold tracking-tight text-white">Membre Welcome</h3>
                  <p className="mt-6 text-base leading-7 text-gray-200">
                  Les fonctions de prise de RDV et règlement des séances ne sont accessibles que pour les membres Spring Medicare ayant souscrit à la formule Koudepouce Welcome.
                  </p>
                  <p className="mt-6 text-base leading-7 text-gray-200">
                  Nous sommes navrés mais vous ne pouvez pas encore prendre RDV avec ce praticien via notre site. 
                  </p>
                  <p className="mt-4 leading-6 text-green-200">
                    <a href="/solution/membre/premium/">
                      <StaticImage
                        className="bg-white rounded-md"
                        src="../images/spring-medicare-membre.jpg"
                        alt=""
                      />
                    </a>
                  </p>
                  <div className="mt-8">
                    <a
                      href="/solution/membre/premium/"
                      className="inline-flex items-center rounded-md ring-1 ring-spring-bleu bg-spring-bleu px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    >
                      Passez à l'offre Koudepouce Premium
                    </a>
                  </div>
                </div>
            </div> */}
        </aside>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        reference
        date
        slug
        fil_ariane
        title
        template
        modele
        title_meta
        description_meta
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        expert_nom
        expert_prenom
        expert_cabinet
        expert_adresse
        expert_cp
        expert_ville
        expert_email
        expert_telephone
        expert_site
        expert_linkedin
      }
      body
    }
  }
`;

export default MdxPage;
